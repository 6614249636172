module.exports = {
  siteTitle: '5 Park Living', // <title>
  manifestName: '5 Park',
  manifestShortName: '5 Park Living', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#ffffff',
  manifestThemeColor: '#ffffff',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  // pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: '5 Park - MIAMI BEACH',
  logo: 'src/assets/img/logo.png',
  subHeading: 'BAY AND OCEAN RESIDENCES',
  // social
  socialLinks: [
    // {
    //   style: 'brands',
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/anubhavsrivastava',
    // },

    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:inquiries@fiveparkliving.com',
    },
    {
      style: 'solid',
      icon: 'fa-phone',
      name: 'Call',
      url: 'tel:7862178174',
    },
  ],
};
